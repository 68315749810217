<template>
  <div class="detail-page">
    <div class="handle-area">
      <a-button class="margin-l40 width-120" type="danger" icon="delete" @click="delItem" v-has-permission="['deleteCustomerStakeholder']">删除</a-button>
    </div>
    <a-row :gutter="10">
      <a-col span="16">
        <a-card title="客户信息" :bodyStyle="{'height': '400px'}">
          <div slot="extra">
            <a-button size="small" type="primary" ghost @click="infoSubmit" v-has-permission="['editCustomerStakeholder']">保存</a-button>
          </div>
          <a-form-model
              ref="infoForm"
              labelAlign="right"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
              :model="detail"
              :rules="infoRules"
              :hideRequiredMark="true"
          >
            <a-row :gutter="10">
              <a-col span="12">
                <a-form-model-item label="客户干系人姓名" prop="stakeholderName">
                  <a-input placeholder="请输入客户干系人姓名" v-model="detail.stakeholderName" :disabled="!canModify" />
                </a-form-model-item>
                <a-row :gutter="10">
                  <a-col span="16">
                    <a-form-model-item  label="性别" prop="gender" :label-col="{ span:9 }" :wrapper-col="{ span: 11 }">
                      <a-select v-model="detail.gender" placeholder="请选择性别" :disabled="!canModify">
                        <a-select-option :value="1">男</a-select-option>
                        <a-select-option :value="2">女</a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                  <a-col span="8">
                    <a-form-model-item label="年龄" prop="age">
                      <a-input placeholder="请输入年龄" type="number" v-model="detail.age" :disabled="!canModify" />
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-form-model-item  label="职务" prop="jobTitle">
                  <a-input placeholder="请输入职务" type="text" v-model="detail.jobTitle" :disabled="!canModify" />
                </a-form-model-item>
                <a-form-model-item label="联系电话" prop="phone">
                  <a-input placeholder="请输入联系电话" v-model="detail.phone" :disabled="!canModify" />
                </a-form-model-item>
                <a-form-model-item label="邮箱" prop="email">
                  <a-input placeholder="请输入邮箱" v-model="detail.email" :disabled="!canModify" />
                </a-form-model-item>
                <a-form-model-item label="省/城市" prop="province">
                  <District :level="2" @change="getDistrict" v-model="detail.province" :disabled="!canModify"></District>
                </a-form-model-item>
                <a-form-model-item label="详细地址" prop="address">
                  <a-textarea placeholder="请输入详细地址" v-model="detail.address" :auto-size="{ minRows: 3, maxRows: 5 }" :disabled="!canModify" />
                </a-form-model-item>
              </a-col>
              <a-col span="12">
                <a-form-model-item  label="所属客户" prop="customerId">
                  <a-select v-model="detail.customerId" placeholder="请选择所属客户" :disabled="!canModify">
                    <a-select-option :value="item.id" v-for="(item) in customerList" :key="item.id">{{ item.customerName }}</a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item  label="干系人级别" prop="stakeholderLevel">
                  <a-select v-model="detail.stakeholderLevel" placeholder="请选择干系人级别" :disabled="!canModify">
                    <a-select-option :value="1">主要干系人</a-select-option>
                    <a-select-option :value="2">干系人</a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="备注" prop="remark">
                  <a-textarea placeholder="请输入备注" v-model="detail.remark" style="height: 250px" :disabled="!canModify" />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </a-col>
      <a-col span="8">
        <a-card title="合作项目" :bodyStyle="{'height': '400px'}">
          <div slot="extra">
            <span>{{ detail.totalProjectNum }}（{{ detail.goingProjectNum }}）</span>
          </div>
          <div class="line-h30">
            <div class="display-f justify-between" v-for="(item, index) in detail.projectInfos" :key="item.id">
              <span>{{ item.projectName }}</span>
              <span class="font-c9 margin-l20">{{ item.stakeholderLevel === 1 ? '主要干系人' : '干系人' }}</span>
              <span class="font-c9 margin-l20"> {{ projectStatusName[item.projectStatus] }}</span>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-row :gutter="10" class="margin-t15">
      <a-col span="24">
        <a-card title="日志">
          <a-table
              :columns="logColumns"
              :data-source="detail.projectLogs"
              rowKey="id"
              bordered
          ></a-table>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
import { getCustomerStakeholderDetail, createCustomerStakeholder, deleteCustomerStakeholder } from '../../api/customer/customerStakeholder'
import { getCustomerList } from '../../api/customer/customer'
import District from '@/components/district/district'
import { mapState } from 'vuex'

export default {
  name: 'customerStakeholderDetail',
  components: {
    District
  },
  data () {
    return {
      detail: {},
      infoRules: {
        customerName: [
          { required: true, message: '请输入客户名称', trigger: 'blur' }
        ],
        customerLevel: [
          { required: true, message: '请选择客户级别', trigger: 'change' }
        ],
        province: [
          { required: true, message: '请选择省/城市', trigger: 'change' }
        ]
      },
      districtList: [],
      logColumns: [
        {
          title: '时间',
          dataIndex: 'createTime'
        },
        {
          title: '事件内容',
          dataIndex: 'logContent'
        },
        {
          title: '触发人',
          dataIndex: 'creator'
        }
      ],
      projectStatusName: ['', '洽谈中', '规划中', '实施中', '验收中', '已完成', '已取消', '已搁置'],
      customerList: [],
      canModify: true
    }
  },
  mounted () {
    this.getDetail()
    this.getCustomerList()
    this.canModify = this.authority.editCustomerStakeholder
  },
  methods: {
    moment,
    getDetail () {
      getCustomerStakeholderDetail({
        id: this.$route.query.id
      }).then(res => {
        this.detail = res.data
        this.detail.province = [res.data.privince, res.data.city]
      })
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteCustomerStakeholder({
            id: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getMember()
          })
        }
      })
    },
    infoSubmit () {
      this.$refs.infoForm.validate(valid => {
        if (valid) {
          createCustomerStakeholder(this.detail).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    },
    getCustomerList () {
      getCustomerList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.customerList = res.data.list
      })
    },
    getDistrict (data) {
      this.detail.privince = data[0]
      this.detail.city = data[1]
    },
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
