import request from '@/utils/request'

export function getCustomerStakeholderList(params) {
    return request({
        url: '/customerStakeholder/page',
        method: 'GET',
        params,
    })
}

export function createCustomerStakeholder(data) {
    return request({
        url: data.id ? `/customerStakeholder/update/${data.id}` : `/customerStakeholder/create`,
        method: 'POST',
        data,
    })
}

export function deleteCustomerStakeholder(data) {
    return request({
        url: '/customerStakeholder/delete',
        method: 'POST',
        data,
    })
}

export function getCustomerStakeholderDetail(params) {
    return request({
        url: '/customerStakeholder/getById',
        method: 'GET',
        params,
    })
}





